import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[(!_vm.getEmailConfirmed)?_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c('email-alert')],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c(VCard,{attrs:{"tile":"","elevation":"2"}},[_c(VCardTitle,{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('customerportal-latest-registrations'))+" ")]),_c(VDataTable,{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.customerRegistrationRowsList,"items-per-page":10,"sort-by":'utcRegistrationDate',"sort-desc":"","footer-props":{
                        itemsPerPageOptions: [10,20,50, -1],
                        'items-per-page-text': _vm.$t('entranceList-nrOfItems')
                    }},scopedSlots:_vm._u([{key:"item.utcRegistrationDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.utcRegistrationDate,{ input: "formatutc", output: "formatlocal" }))+" ")]}},{key:"item.orderItemPriceInMinorUnit",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.globalConvertMinorCurrencyToLocal(item.orderItemPriceInMinorUnit))+" ")]}},{key:"item.orderStatus",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('paymentStatus-'+item.orderStatus))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }