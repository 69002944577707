<template>
    <v-container fluid>
        <v-row>
            <v-col
                v-if="!getEmailConfirmed"
                cols="12"
                md="7"
            >
                <email-alert />
            </v-col>
            <v-col
                cols="12"
                md="7"
            >
                <v-card
                    tile
                    elevation="2"
                > 
                    <v-card-title class="py-1">
                        {{ $t('customerportal-latest-registrations') }}
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="customerRegistrationRowsList"
                        :items-per-page="10"
                        class="elevation-2"
                        :sort-by="'utcRegistrationDate'"
                        sort-desc
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50, -1],
                            'items-per-page-text': $t('entranceList-nrOfItems')
                        }"
                    >
                        <template v-slot:item.utcRegistrationDate="{ item }">
                            {{ item.utcRegistrationDate | luxon({ input: "formatutc", output: "formatlocal" }) }}
                        </template>
                        <template v-slot:item.orderItemPriceInMinorUnit="{ item }">
                            {{ globalConvertMinorCurrencyToLocal(item.orderItemPriceInMinorUnit) }}
                        </template>
                        <template v-slot:item.orderStatus="{ item }">
                            {{ $t('paymentStatus-'+item.orderStatus) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import emailAlert from '@/views/shared/EmailNotConfirmedAlert'

export default {
    components: {
        emailAlert
    }, 
    data() {
        return {
            headers: [
                {
                    text: this.$t('imagetype-3'),
                    sortable: true,
                    value: 'registrationNumber',
                },
                {
                    text: this.$t('registrations-time'),
                    sortable: true,
                    value: 'utcRegistrationDate',
                },              
                {
                    text: this.$t('registrations-location'),
                    sortable: true,
                    value: 'location.name',
                }
            ],
            customerRegistrationRowsList: []
        }
    },        
    computed: {        
        locale(){
            return process.env.VUE_APP_I18N_LOCALE
        },
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed',
            getSelectedCvrNumber: 'getSelectedCvrNumber',
            getSelectedPNumber: 'getSelectedPNumber'            
        })
    },
    watch: {
    }, 
    created() {
        if(this.locale !== 'da'){
            this.FetchUser({
                pNumber: this.getSelectedCvrNumber
            })
                .then(() => {
                    this.FetchCustomerRegistrationRowsList({pNumber: this.getSelectedCvrNumber})
                        .then(result =>{
                            this.customerRegistrationRowsList = result.registrations
                        })
                    
                })
            
        }else{
            this.FetchUser({
                pNumber: this.getSelectedPNumber
            })
                .then(() => {
                    this.FetchCustomerRegistrationRowsList({pNumber: this.getSelectedPNumber})
                        .then(result =>{
                            this.customerRegistrationRowsList = result.registrations
                        })
                    
                })
        }
                
    },
    methods: {
        ...mapActions({
            FetchUser: 'FetchUser',
            FetchCustomerRegistrationRowsList: 'FetchCustomerRegistrationRowsList'
        }),
        ...mapMutations({
            setSelectedPNumber: 'setSelectedPNumber'
        }),
        
    }
}
</script>